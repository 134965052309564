import moment from "moment"

const defaultDurationFormat = "h[h] m[min] s[s]"
const defaultDurationFormatMS = "h[h] m[min] s[s] SSS[ms]"
const defaultDateFormat = "ll"
const defaultTimeFormat = "LT"
const defaultDateTimeFormatWithSeconds = "ll LTS"
const defaultDateTimeFormat = "ll LT"

const timeHelpers = {

  // Format a duration in milliseconds to a human-readable string
  // If the duration is less than 1 second, the format includes milliseconds
  // @param {Number} durationTimestamp
  // @return {String}
  formatDurationMs(durationTimestamp) {
    const duration = moment.duration(durationTimestamp)
    const format = duration.asSeconds() < 1 ? defaultDurationFormatMS : defaultDurationFormat

    return duration.format(format)
  },

  // Format a duration in milliseconds to a human-readable string
  // If the duration is less than 1 second, it returns the duration is s with 1 decimal rounded to the nearest tenth (0.01s -> 0.1s)
  // If the duration is less than 100ms, it returns <0.1s
  // If the duration is 0, it returns 0s
  // @param {Number} durationTimestamp
  // @return {String}
  formatDuration(durationTimestamp) {
    const duration = moment.duration(durationTimestamp)

    if (duration.asMilliseconds() === 0) return "0s"
    if (duration.asMilliseconds() < 100) return "0.1s"
    if (duration.asSeconds() < 1) return `${duration.asSeconds().toFixed(1)}s`

    return duration.format(defaultDurationFormat, { trim: "both" })
  },

  formatTimeDateWithSeconds(date) {
    return moment(date).format(defaultDateTimeFormatWithSeconds)
  },

  formatDate(date) {
    return moment(date).format(defaultDateFormat)
  },

  formatTime(date) {
    return moment(date).format(defaultTimeFormat)
  },

  formatDateTime(date) {
    return moment(date).format(defaultDateTimeFormat, { trim: true })
  },

  // Format a date range to a human-readable string
  // If the start and end dates are on the same day, it returns the date and time range
  // If the start and end dates are on different days, it returns the date and time range
  // @param {Number} start
  // @param {Number} end
  formatDateRange(start, end, separator = "→") {
    const startDate = moment(start)
    const endDate = moment(end)

    const formatedStartDate = this.formatDateWithoutYear(startDate, defaultDateTimeFormat) || "?"
    const formatedEndDate = this.formatDateWithoutYear(endDate, defaultDateTimeFormat)  || "?"
    const formatedEndTime = endDate.format(defaultTimeFormat)
    const formatedEndDateOfTime = startDate.isSame(endDate, "day") ? formatedEndTime : formatedEndDate

    return [
      formatedStartDate,
      separator,
      formatedEndDateOfTime,
    ].join(" ")
  },

  /**
   * Format date without year
   * Use any moment.js format and it will remove the year from it
   *
   * Borrowed from https://stackoverflow.com/a/44308074
   * @param date
   * @param {string} format moment.js format
   * @returns {string}
   */
  formatDateWithoutYear(date, format) {
    const year = moment(date).format("YYYY")
    return moment(date).format(format)
      .replace(year, "")
      .replace(/\s\s+/g, " ")// remove double spaces, if any
      .trim() // remove spaces from the start and the end
      .replace(/[рг]\./, "") // remove year letter from RU/UK locales
      .replace(/de$/, "") // remove year prefix from PT
      .replace(/b\.$/, "") // remove year prefix from SE
      .trim() // remove spaces from the start and the end
      .replace(/,$/g, "") // remove comma from the end
  },

  formatDateRangeDuration(start, end) {
    if (!start || !end) return ""

    const startDate = moment(start)
    const endDate = moment(end)

    return moment.duration(endDate.diff(startDate)).format("D_ h_ m_")

  },

  /**
   * Return formatted date with year if it's not the current year
   * @param date
   * @param {string} format moment.js format
   */
  autoFormatDate(date, format = defaultDateFormat) {
    if(moment(date).year() === moment().year()) {return this.formatDateWithoutYear(date, format)}

    return this.formatDate(date)
  },

  /**
   * Return formatted date and time with year if it's not the current year
   * @param date
   * @returns {string}
   */
  autoFormatDateTime(date) {
    return this.autoFormatDate(date, defaultDateTimeFormat)
  },

  /**
   * Checks if two dates fall on the same day.
   *
   * @param {Date, String} date1 - The first date to compare.
   * @param {Date, String} date2 - The second date to compare.
   * @returns {boolean} Returns true if the dates are on the same day, false otherwise.
   */
  isSameDay(date1, date2) {
    return moment(date1).isSame(date2, "day")
  },
}
export default timeHelpers
